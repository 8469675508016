import React, { useEffect } from 'react';
import { UserCard } from "../../Homescreen/UserCard";
import './style.slider.css';

const userData = [
  {
    paragraph: "Amet morbi enim sodales quis dui, in habitant pharetra. Risus id fringilla sed adipiscing volutpat sit varius turpis. Sed pretium semper rhoncus, tellus semper.",
    name: "Ralph Edwards",
    role: "Product Designer",
    imageClassName: "user-card-2",
    categoryClassName: 'user-card-3',
    categoryClassNameOverride: 'user-card-4'
  },
  {
    paragraph: "Non risus viverra enim, quis. Eget vitae arcu vivamus sit tellus, viverra turpis lorem. Varius a turpis urna id porttitor.",
    name: "Hellen Jummy",
    role: "Team Lead",
    imageClassName: "user-card-5",
    categoryClassName: 'user-card-3',
    categoryClassNameOverride: 'user-card-4'
  },
  {
    paragraph: "Aliquet ridiculus mi porta habitant vulputate rhoncus, mattis amet enim. Sit purus venenatis velit semper lectus sed ornare quam nulla. Lacus, ut congue sagittis vel nisi integer imperdiet a vitae.",
    name: "Hellena John",
    role: "Co-founder",
    imageClassName: "user-card-8",
    categoryClassName: 'user-card-3',
    categoryClassNameOverride: 'user-card-4'
  },
  {
    paragraph: "A eget sed posuere dui risus habitasse mauris. Venenatis aliquet id ultrices a lacus. Pretium vehicula pretium posuere justo sed lorem cursus.",
    name: "David Oshodi",
    role: "Manager",
    imageClassName: "user-card-9",
    categoryClassName: 'user-card-3',
    categoryClassNameOverride: 'user-card-4'
  },
  {
    paragraph: "Magna egestas aliquet ut integer non. Sed diam enim nibh sit. Aliquam laoreet aenean metus nibh eu scelerisque.",
    name: "Charolette Hanlin",
    role: "CEO",
    imageClassName: "user-card-10",
    categoryClassName: "user-card-3",
    categoryClassNameOverride: "user-card-4"
  }
]

const Slider = () => {

  useEffect(() => {
    // const slider = document.querySelector(".frame .slider");

    // const observer = new IntersectionObserver(
    //   ([entry]) => {
    //     if (entry.isIntersecting) {
    //       slider?.classList.add("in-view");
    //     } else {
    //       slider?.classList.remove("in-view");
    //     }
    //   },
    //   { threshold: 0.5 }
    // );

    // if (slider) {
    //   observer.observe(slider);
    // }

    // return () => {
    //   if (slider) {
    //     observer.unobserve(slider);
    //   }
    // };
  }, []);

  return (
    <div className="testimonial-section">
      <div className="testimonial-section-inner">
        
        <h2 className="main-heading"> Making a difference in our users’ life </h2>
        <div className="slider">
          {userData.map((item, index) => (
            <div key={index} className="content-box">
              <div className="content">
                <p className="paragraph">
                  {item.paragraph}
                </p>
                <UserCard
                  categoryClassName={item.categoryClassName}
                  categoryClassNameOverride={item.categoryClassNameOverride}
                  className="user-card-instance"
                  layout="horizontal"
                  name={item.name}
                  role={item.role}
                  size="l"
                  userThumbSizeMClassName={item.imageClassName}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Slider;
