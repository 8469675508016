import React, { useRef, useState } from 'react';
import './style.video.css';

const VideoComponent = ({ videoSrc, thumbnailSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play().catch((error) => console.error('Error playing video:', error));
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleFullscreen = () => {
    if (videoRef.current) {
      if (isFullscreen) {
        document.exitFullscreen();
      } else {
        videoRef.current.requestFullscreen();
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  return (
    <div className={`video-container ${isFullscreen ? 'fullscreen' : ''}`}>
      <video
        ref={videoRef}
        src={videoSrc}
        poster={thumbnailSrc} // Add thumbnail here
        className="video"
        controls={false} // Hide default controls
      />
      <button className="play-pause-btn" onClick={togglePlayPause}>
        {isPlaying ? (
          <img alt="Pause" src="/img/pause.svg" />
        ) : (
          <img alt="Play" src="/img/play-white.svg" />
        )}
      </button>
    </div>
  );
};

export default VideoComponent;
